import React from 'react'
import Layout from './layout'
import { graphql, StaticQuery } from 'gatsby'
import ReCAPTCHA from "react-google-recaptcha";
import env from "../../client-config";

import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import styles from './contact.module.css'
import general from '../styles/general.module.css'
import axios from 'axios'
import SEO from '../components/seo'

const query = graphql`
query ContactQuery {
    contact: sanityContentPage(directory: {eq: "/contact"}) {
        id
        title
        subTitle
        directory
    }
  }
`

class ContactPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            onRecaptcha: false,
            submitted: false
        }
    }

    onClickSubmit = (recaptchaValue, values) => {
        if (this.state.onRecaptcha) {
            if (recaptchaValue !== "") {
                axios({
                    method: "POST",
                    url: "https://getform.io/f/7e716176-0cdb-41c3-90be-85746b4cb710",
                    data: values
                }).then((response) => {
                    if (response.status === 200) {
                        window.location.href = "/contact/thank-you"; // similar behavior as clicking on a link
                    } else {
                        //Something went wrong, give a general contact submit failed response.
                        alert("Something went wrong with the Contact-Form submit, please try again later");
                    }
                })
            }
            else {
                alert("Please check the captcha box");
            }
            //return recaptchaValue;
        } 
        
        this.setState({
            onRecaptcha: true
        })
    }

    render() {
        return (
            <StaticQuery
                query={query}
                render={data => {
                    if (!data.contact) {
                        throw new Error(
                            'Missing "Contact". Open the Studio and add some content'
                        )
                    }

                    const contactData = data.contact;

                    const schema = yup.object({
                        firstName: yup.string().required(),
                        lastName: yup.string().required(),
                        organization: yup.string().required(),
                        email: yup.string().email().required(),
                        message: yup.string().required()
                    });

                    const recaptchaRef = React.createRef();

                    const recaptcha = this.state.onRecaptcha ? 
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={env.recaptcha.siteKey}
                                    /> : null

                    return (
                        <Layout>
                            <SEO
                                title={contactData.title}
                                description={contactData.subTitle}
                            />
                            <div className={styles.root}>
                                <div className={styles.titleContainer}>
                                    <h1>{contactData.title}</h1>
                                    <p>{contactData.subTitle}</p>
                                </div>
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={(values, actions) => {
                                        const recaptchaValue = recaptchaRef.current ? recaptchaRef.current.getValue() : '';
                                        this.onClickSubmit(recaptchaValue, values);
                                    }}
                                    initialValues={{
                                        firstName: '',
                                        lastName: '',
                                        organization: '',
                                        email: '',
                                        message: ''
                                    }}
                                >
                                {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                                }) => (
                                <Form className={styles.form} noValidate onSubmit={handleSubmit}>
                                    <Form.Group className={styles.formGroup}>
                                        <Form.Label><p>Your first name*</p></Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="firstName"
                                            placeholder="Enter your first name"
                                            value={values.firstName}
                                            onChange={handleChange} 
                                            isValid={touched.firstName && !errors.firstName}
                                            isInvalid={touched.firstName && !!errors.firstName}
                                        />
                                        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className={styles.formGroup}>
                                        <Form.Label><p>Your last name*</p></Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="lastName"
                                            placeholder="Enter your last name" 
                                            value={values.lastName}
                                            onChange={handleChange} 
                                            isValid={touched.lastName && !errors.lastName}
                                            isInvalid={touched.lastName && !!errors.lastName}
                                        />
                                        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className={styles.formGroup}>
                                        <Form.Label><p>Your organization*</p></Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="organization"
                                            placeholder="Enter your organization"
                                            value={values.organization}
                                            onChange={handleChange} 
                                            isValid={touched.organization && !errors.organization}
                                            isInvalid={touched.organization && !!errors.organization}
                                        />
                                        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className={styles.formGroup}>
                                        <Form.Label><p>Your e-mail address*</p></Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            name="email"
                                            placeholder="example: risk@eppr.com"
                                            value={values.email}
                                            onChange={handleChange} 
                                            isValid={touched.email && !errors.email}
                                            isInvalid={touched.email && !!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email === "email is a required field" ? "This field is required" : "This field is must be a valid email"}</Form.Control.Feedback>
                                    </Form.Group>
                                    <div className={styles.bottomContainer}>
                                        <Form.Group className={styles.formGroup_message}>
                                            <Form.Label><p>Your message*</p></Form.Label>
                                            <Form.Control 
                                                name="message"
                                                as="textarea" 
                                                rows="5" 
                                                placeholder={touched.message && !!errors.email ? "Please enter your message" : "Enter your message"}
                                                value={values.message}
                                                onChange={handleChange} 
                                                isValid={touched.message && !errors.message}
                                                isInvalid={touched.message && !!errors.message}
                                            />
                                            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Button type="submit" value="Submit" className={general.button}>Submit</Button>
                                        <div className={styles.recaptcha}>
                                            {recaptcha}
                                        </div>
                                    </div>
                                </Form>
                                )}
                                </Formik>
                            </div>
                        </Layout>
                    )
                }}
            />
        )
    }
}

export default ContactPage