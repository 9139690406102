import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PortableText from '@sanity/block-content-to-react'

import styles from './footer.module.css'

import Media from 'react-media';

const query = graphql`
query FooterQuery {
    footer: sanityFooterSettings {
      footerLinks {
        title
        url
        mainImage {
          caption
          asset {
            fluid {
              src
            }
          }
        }
      }
      _rawDisclaimerText
    }
  }
`

function Footer(props) {
    return (
        <StaticQuery
            query={query}
            render={data => {

                const footerLinks = data.footer ? data.footer.footerLinks : null;
                const disclaimerText = data.footer ? data.footer._rawDisclaimerText : null;

                var footerLinksComponent = footerLinks ? footerLinks.map((link, idx) => {
                    return (
                        <a href={link.url} key={idx} className={styles.linkBox}>
                            <img className={styles.linkImage}
                                src={link.mainImage.asset.fluid.src}
                                alt={link.title}
                            />
                        </a>
                    )
                }) : null;

                if (!data.footer) {
                    throw new Error(
                        'Missing "Footer settings". Open the Studio at http://localhost:3333 and some content in "Footer settings"'
                    )
                }

                return (
                    <div className={styles.root}>
                        <Media query="(min-width: 751px)" render={() =>
                            (
                                <div>
                                    <div className={styles.footerLinks}>
                                        {footerLinksComponent}
                                    </div>
                                    <div className={styles.disclaimerText}>
                                        {disclaimerText && disclaimerText[0].children[0] && (
                                            <PortableText 
                                                blocks={disclaimerText}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        />
                        <Media query="(max-width: 750px)" render={() =>
                            (
                                <div>
                                     <div className={styles.disclaimerText}>
                                        {disclaimerText && disclaimerText[0].children[0] && (
                                            <PortableText 
                                                blocks={disclaimerText}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.footerLinks}>
                                        {footerLinksComponent}
                                    </div>
                                </div>
                            )}
                        />
                    </div>

                )
            }
            } />
    )
}

export default Footer
