import { Link } from 'gatsby'
import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { Dropdown } from 'react-bootstrap';

import Media from 'react-media';
import styles from './header.module.css'

import { MdMenu, MdClose } from "react-icons/md";

const query = graphql`
query HeaderQuery {
  site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    title
    banner {
      image {
        asset {
          fluid {
            src
          }
          url
        }
      }
      bannerDescription
      bannerTitle
      alt
    }
  }
  header: sanityHeaderSettings {
    headerImageLeft {
      url {
        href
      }
      alt
      description
      smallImage {
        asset {
          fluid {
            src
          }
        }
      }
      FullImage {
        asset {
          fluid {
            src
          }
        }
      }
    }
    headerImageRight {
      url {
        href
      }
      alt
      smallImage {
        asset {
          fluid {
            src
          }
        }
      }
      FullImage {
        asset {
          fluid {
            src
          }
        }
      }
    }
    headerMenuLinks {
      _key
      title
      internalLink {
        reference {
          ... on SanityContentPage {
            directory
            title
          }
        }
      }
    }
  }
}
`

function Header(props) {
  const { onHideNav, onShowNav, showNav, siteTitle } = props;

  const isIndex = props.isIndex;  

  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <StaticQuery
      query={query}
      render={data => {

        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }

        if (!data.header) {
          throw new Error(
            'Missing "Header settings". Open the Studio at http://localhost:3333 and some content in "Header settings"'
          )
        }

        const banner = data.site ? data.site.banner : null;
        const leftLogo = data.header ? data.header.headerImageLeft : null;
        const rightLogo = data.header ? data.header.headerImageRight : null;
        const menuLinks = data.header ? data.header.headerMenuLinks : null;

        const bannerImage = banner.image ? banner.image.asset.fluid.src : null;

        var background = {
          backgroundImage: `url(${bannerImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }

        var menuComponent = menuLinks.map(link => {
          const directory = link.internalLink.reference.directory;
          const title = link.internalLink.reference.title;
          const path = directory!=='/' ? directory + '/' + title.replace(/\s+/g, '-').toLowerCase() : directory;

          return (
            <Link to={path} key={link._key} style={{ textDecoration: 'none' }}>
              <p>{link.title}</p>
            </Link>
          )
        });

        var homePageMenuComponent = menuLinks.map(link => {
          const directory = link.internalLink.reference.directory;
          const title = link.internalLink.reference.title;
          const path = directory!=='/' ? directory + '/' + title.replace(/\s+/g, '-').toLowerCase() : directory;

            return ((directory == '/about' || directory == '/contact') ? 
                <Link to={path} key={link._key} style={{ textDecoration: 'none' }}>
                  <p>{link.title}</p>
                </Link> : ""
            )
        });

        const menuToggle = React.forwardRef(({ children, onClick }, ref) => (
          <a
            href=""
            ref={ref}
            onClick={e => {
              e.preventDefault();
              onClick(e);
            }}
          >
            {children}
          </a>
        ));

        var dropdownMenu = menuLinks.map(link => {
          const directory = link.internalLink.reference.directory;
          const title = link.internalLink.reference.title;
          const path = directory!=='/' ? directory + '/' + title.replace(/\s+/g, '-').toLowerCase() : directory;

          return (
              <Dropdown.Item key={link._key} className={styles.dropDownItem} href={path}>
                  {link.title}
              </Dropdown.Item>
          )
        });

        return (
          <div>
            <Media query="(min-width: 751px)" render={() =>
              (
                isIndex ? (<div className={styles.root} style={background}>
                  <div className={styles.index_wrapper}>
                    <div className={styles.index_logoWrapper}>
                      <div className={styles.navContainer}>
                        <div className={styles.index_links}>
                          {homePageMenuComponent}
                        </div>
                        <div className={styles.logos}>
                          <a href={leftLogo.url.href}>
                            {leftLogo && (
                              <img className={styles.index_leftLogo}
                                src={leftLogo.FullImage.asset.fluid.src}
                                alt={leftLogo.alt}
                              />
                            )}
                          </a>
                          <a href={rightLogo.url.href}>
                            {rightLogo && (
                              <img className={styles.index_rightLogo}
                                src={rightLogo.FullImage.asset.fluid.src}
                                alt={rightLogo.alt}
                              />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                      <div className={styles.index_bannerText}>
                        <h2 className="header">{banner.bannerTitle}</h2>
                        <p>{banner.bannerDescription}</p>
                      </div>
                  </div>
                </div>)
                  : (
                    <div className={styles.root}>
                      <div className={styles.wrapper}>
                        <div className={styles.logoWrapper}>
                          <div className={styles.leftLogoWrapper}>
                            <a href={leftLogo.url.href}>
                              {leftLogo && (
                                <img className={styles.leftLogo}
                                  src={leftLogo.smallImage.asset.fluid.src}
                                  alt={leftLogo.alt}
                                />
                              )}
                            </a>
                          </div>

                          <div className={styles.textWrapper}>
                              <div className={styles.bannerText}>
                                <h1>{banner.bannerTitle}</h1>
                              </div>
                            <div className={styles.links}>
                              {menuComponent}
                            </div>
                          </div>

                          <a href={rightLogo.url.href}>
                            {rightLogo && (
                              <img className={styles.rightLogo}
                                src={rightLogo.FullImage.asset.fluid.src}
                                alt={rightLogo.alt}
                              />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  )
              )}
            />
            <Media query="(max-width: 750px)" render={() =>
              (
                <div className={styles.root} style={background}>
                  <div className={styles.index_wrapper}>
                    <div className={styles.index_logoWrapper}>
                      <div className={styles.navContainer}>
                        <div className={styles.logos}>
                          <a href={leftLogo.url.href}>
                            {leftLogo && (
                              <img className={styles.index_leftLogo}
                                src={leftLogo.FullImage.asset.fluid.src}
                                alt={leftLogo.alt}
                              />
                            )}
                          </a>
                        </div>
                        <Dropdown className={menuOpened ? styles.dropdown_open : styles.dropdown_close}
                          onToggle={() => setMenuOpened(!menuOpened)} alignRight={true}>
                          <Dropdown.Toggle as={menuToggle} id="dropdown-custom-components">
                            {!menuOpened && <MdMenu />}
                            {menuOpened && <MdClose />}
                          </Dropdown.Toggle>

                            <Dropdown.Menu style={{transitionDuration: "400ms", transform: "translate(0px, 29px)"}}>
                              {dropdownMenu}
                            </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                      <div className={styles.index_bannerText}>
                        <h1>{banner.bannerTitle}</h1>
                      </div>
                  </div>
                </div>
              )}
            />
          </div>
        )
      }}
    />
  )
}

export default Header
